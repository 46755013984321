import React from 'react';
import AppLayout from '../components/layout/AppLayout';
import DashboardInitialize from '../components/old-version/DashboardInitiaize/DashboardInitiaize';

const App = () => (
  <AppLayout>
    <DashboardInitialize />
  </AppLayout>
);

export default App;
